import React from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import Container from './container'

export default function PageHeading({heading}) {
  return (
    <StyledComponent className="page-heading">
      <Fade>
        <Container type="wide" className="page-heading-container">
          <h2>{heading}</h2>
          {/* <i></i> */}
        </Container>
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  margin-bottom: 6vw;

  @media (min-width: 700px) {
    margin-bottom: 3rem;
  }

  .page-heading-container {
    display: flex;
    flex-direction: row;

    @media (min-width: 1200px) {
      padding: 0 5rem;
    }
  }

  h2 {
    flex-shrink: 0;
    font-family: "Aktifo B Book", sans-serif;
    font-size: 18.3pt;
    font-weight: normal;
    line-height: 1.2;
    margin: 0;
    text-transform: uppercase;
    width: 100%;
  }
}`
