export const PUBLIC_COLLECTIONS_DATA = [
  {
    collection: 'Abbot Hall',
  },
  {
    collection: 'Bradford & Scunthorpe Museum',
  },
  {
    collection: 'Brighton & Hove Museum',
  },
  {
    collection: 'Canterbury Museum',
  },
  {
    collection: 'Cartwright Hall Art Gallery',
  },
  {
    collection: 'Carlisle Museum & Art Gallery',
  },
  {
    collection: 'Chantry Bequest',
  },
  {
    collection: 'Cheltenham & Gloucester',
  },
  {
    collection: 'Cooper Gallery',
  },
  {
    collection: 'Department of the Environment',
  },
  {
    collection: 'Farendon Trust',
  },
  {
    collection: 'Government Art Collection',
  },
  {
    collection: 'Guinness Collection',
  },
  {
    collection: 'Kendal Museum',
  },
  {
    collection: 'Kent Education Committee',
  },
  {
    collection: 'Kent University',
  },
  {
    collection: 'Lloyds of London',
  },
  {
    collection: 'London Tourist Board',
  },
  {
    collection: 'London Weekend Television',
  },
  {
    collection: 'Loughborough University',
  },
  {
    collection: 'Maidstone Museum',
  },
  {
    collection: 'Ministry of Works',
  },
  {
    collection: 'Monte Carlo Museum',
  },
  {
    collection: 'National Museum of Wales',
  },
  {
    collection: 'National Portrait Gallery',
  },
  {
    collection: 'National Trust',
  },
  {
    collection: 'New Metropole Art Collection',
  },
  {
    collection: 'North Lincolnshire Museum',
  },
  {
    collection: 'Preston Museum',
  },
  {
    collection: 'Royal Academy of Arts Collection',
  },
  {
    collection: 'Royal Collection Trust',
  },
  {
    collection: 'Royal College of Art Collection',
  },
  {
    collection: 'Ruth Borchard Collection',
  },
  {
    collection: 'Salford Museum & Art Gallery',
  },
  {
    collection: 'Scunthorpe Museum',
  },
  {
    collection: 'Shell',
  },
  {
    collection: 'Southend Museum',
  },
  {
    collection: 'St John’s College Cambridge',
  },
  {
    collection: 'St John’s College Oxford',
  },
  {
    collection: 'Stormont Gallery, Rye',
  },
  {
    collection: 'Towneley Hall Museum',
  },
  {
    collection: 'Towner Gallery, Eastbourne',
  },
  {
    collection: 'WH Smith Ltd',
  },
  {
    collection: 'Williamson Museum',
  },
  {
    collection: 'Worcester College, Oxford',
  },
]
