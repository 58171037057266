import React from 'react'
import {graphql} from 'gatsby'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import Layout from './../components/layout'
import Container from './../components/container'
import PageHeading from './../components/page-heading'
import BodyCopy from './../components/body-copy'
import {stripMarkup} from './../helper-functions/strip-markup'
import {SOLO_EXHIBITIONS_DATA} from './../constants/solo-exhibitions-data'
import {MIXED_EXHIBITIONS_DATA} from './../constants/mixed-exhibitions-data'
import {AWARDS_DATA} from './../constants/awards-data'
import {PUBLIC_COLLECTIONS_DATA} from './../constants/public-collections-data'
import ScrollToTop from './../components/scroll-to-top'

export default props => {
  const {data} = props
  const prismicData = {
    pageHeading: data.prismicAboutPage.data.page_heading.text,
    bodyCopy: data.prismicAboutPage.data.body_copy.html,
    imageAlt: data.prismicAboutPage.data.image.alt,
    imageUrl: data.prismicAboutPage.data.image.url,
    showPhoto: data.prismicAboutPage.data.show_photo,
    quotes: data.prismicAboutPage.data.quotes,
  }

  return (
    <Layout pageName="biography" documentTitlePrefix={prismicData.pageHeading}>
      <section className="biography-section">
        <PageHeading heading={prismicData.pageHeading} />
        <Container doublePadding>
          <BodyAndQuotes>
            <div className="photo-and-body-text">
              {prismicData.imageUrl && prismicData.showPhoto && (
                <img src={prismicData.imageUrl} alt={prismicData.imageAlt} className="biography-photo" />
              )}
              {stripMarkup(prismicData.bodyCopy) && (
                <BodyCopy bodyHtml={prismicData.bodyCopy} classModifier="biography" setMaxWidth={false} />
              )}
            </div>
            <Fade>
              <Quotes>
                {prismicData.quotes &&
                  prismicData.quotes.map((item, index) => (
                    <blockquote key={`quote-${index}`}>
                      <p>{item.quote.text}</p>
                      <footer>{item.citation.text}</footer>
                    </blockquote>
                  ))}
              </Quotes>
            </Fade>
          </BodyAndQuotes>
        </Container>
      </section>

      <PageHeading heading="Résumé" />

      <Container doublePadding>
        <Fade fraction={0}>
          <StyledCvDetailSection id="awards">
            <div className="heading-container">
              <h3>Awards of note</h3>
            </div>
            <ul className="awards__list">
              {AWARDS_DATA.map((item, index) => (
                <li key={`awards-item-${index}`} className="awards__list-item">
                  {item.award}
                </li>
              ))}
            </ul>
          </StyledCvDetailSection>
        </Fade>
        <Fade fraction={0}>
          <StyledCvDetailSection id="public-collections">
            <div className="heading-container">
              <h3>Public Collections</h3>
            </div>
            <ul className="public-collections__list">
              {PUBLIC_COLLECTIONS_DATA.map((item, index) => (
                <li key={`public-collections-item-${index}`} className="public-collections__list-item">
                  {item.collection}
                </li>
              ))}
            </ul>
          </StyledCvDetailSection>
        </Fade>
        <Fade fraction={0}>
          <StyledCvDetailSection id="mixed-exhibitions">
            <div className="heading-container">
              <h3>Mixed Exhibitions</h3>
            </div>

            <ul className="mixed-exhibitions__list">
              {MIXED_EXHIBITIONS_DATA.map((item, index) => (
                <li key={`mixed-exhibitions-item-${index}`} className="mixed-exhibitions__list-item">
                  <span>{item.gallery}</span> <span>{item.location}</span>
                </li>
              ))}
            </ul>
          </StyledCvDetailSection>
        </Fade>
        <Fade fraction={0}>
          <StyledCvDetailSection id="solo-exhibitions" className="solo-exhibitions">
            <div className="heading-container">
              <h3>Solo Exhibitions</h3>
            </div>
            <table className="table table--solo-exhibitions">
              <thead className="sr-only">
                <tr>
                  <th className="col--year">Year</th>
                  <th className="col--gallery">Gallery</th>
                  <th className="col--location">Location</th>
                </tr>
              </thead>
              <tbody>
                {SOLO_EXHIBITIONS_DATA.map((item, index) => (
                  <tr key={`solo-exhibitions-item-${index}`}>
                    <td className="col--year year">{item.year}</td>
                    <td className="col--gallery gallery">{item.gallery}</td>
                    <td className="col--location location">{item.location}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </StyledCvDetailSection>
        </Fade>
      </Container>
      <ScrollToTop />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    prismicAboutPage {
      data {
        page_heading {
          text
        }
        body_copy {
          html
        }
        image {
          url
          thumbnails {
            small {
              url
              alt
            }
            medium {
              url
              alt
            }
          }
          alt
        }
        show_photo
        quotes {
          citation {
            text
          }
          quote {
            text
          }
        }
      }
    }
  }
`

const MainImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 6vw;

  @media (min-width: 700px) {
    margin-bottom: 4.2rem;
  }
`

const RoyalAcademyProfileLink = styled.a`
  margin-bottom: 8rem;
`

const BodyAndQuotes = styled.div`
  @media (min-width: 1300px) {
    display: flex;
    flex-direction: row;
  }

  @media (min-width: 1199px) {
    margin-bottom: 9rem;
  }

  .biography-photo {
    width: 100%;
  }
`

const Quotes = styled.aside`
  margin: 0;
  padding: 0;

  blockquote {
    margin: 0;
    padding: 3rem 0;
    position: relative;

    @media (max-width: 1199px) {
      text-align: center;
    }

    &::after {
      background-color: #909090;
      bottom: 0;
      content: '';
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      width: 10rem;

      @media (max-width: 1199px) {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;

      &::after {
        display: none;
      }
    }
  }

  p {
    font-size: 2.4rem;
    font-style: italic;
    font-family: 'Aktifo B Light', sans-serif;
    margin: 0 0 2rem 0;

    &::before {
      content: '“';
    }

    &::after {
      content: '”';
    }
  }

  footer {
    font-weight: 600;
  }
`
const StyledCvDetailSection = styled.div`
  margin-bottom: 3rem;
  padding-top: 0;
  margin-top: 0;
  /* margin-bottom: 1rem; */

  @media (min-width: 1200px) {
    margin-bottom: 3rem;
    padding-top: 0;
    margin-top: 0;
  }

  /* @media (max-width: 450px) {
    margin-bottom: 0;
  } */

  &.solo-exhibitions {
    margin-bottom: 0;
  }

  .heading-container {
    display: flex;
    flex-direction: row;
    margin: 0 0 2rem 0;
    max-width: 77rem;

    h3 {
      display: inline-block;
      flex-shrink: 0;
      font-size: 2.6rem;
      font-weight: 400;
      margin: 0 1rem 0 0;
      padding: 1.6rem 0;
      position: relative;
      text-transform: uppercase;

      &::after {
        background-color: #909090;
        bottom: 0;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }

  .awards__list {
    font-weight: 600;
    list-style: none;
    margin: 0;
    margin-top: 4rem;
    padding: 0;
  }

  .awards__list-item {
    padding: 0.9rem 0;
  }

  table {
    border-spacing: 0.5rem 1.5rem;
    font-weight: 100;
    max-width: 77rem;
    width: 100%;

    &.table--solo-exhibitions {
      @media (max-width: 450px) {
        display: block;

        tr {
          display: flex;
          flex-direction: column;
          margin-bottom: 2rem;

          &:last-child {
            margin-bottom: 0;
          }
        }

        td {
          width: 100%;
        }
      }
    }
  }

  .col {
    &--year {
      width: 11.3rem;
    }
  }

  tr {
    line-height: 1.3;
    vertical-align: top;
  }

  .year {
    font-weight: 400;
    padding-right: 2vw;
  }

  .mixed-exhibitions__list {
    display: grid;
    font-weight: 100;
    grid-auto-columns: 50%;
    grid-auto-flow: column;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-rows: repeat(29, 1fr);
    list-style: none;
    margin-top: 4rem;
    margin: 0;
    padding: 0;

    @media (max-width: 1100px) {
      grid-column-gap: 8rem;
      grid-auto-columns: 46%;
    }

    @media (max-width: 1000px) {
      grid-auto-columns: 45%;
    }

    @media (max-width: 800px) {
      grid-auto-columns: 44%;
    }

    @media (max-width: 720px) {
      display: flex;
      flex-direction: column;
    }
  }

  .mixed-exhibitions__list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.9rem 9rem 0.9rem 0;

    @media (max-width: 1334px) {
      padding: 0 9rem 0 0;
    }

    @media (max-width: 1100px) {
      padding: 0 0 0 0;
    }

    @media (max-width: 720px) {
      justify-content: flex-start;
      padding: 0.9rem 0;
    }

    @media (max-width: 450px) {
      flex-direction: column;
    }

    span {
      /* border: 1px solid blue; */
      display: inline-block;

      &:first-child {
        @media (max-width: 720px) {
          max-width: 36rem;
          display: block;
          width: 100%;
        }
      }

      &:last-child {
        text-align: right;

        @media (max-width: 720px) {
          text-align: left;
          flex-shrink: 0;
          width: 17rem;
        }
      }
    }
  }

  .public-collections__list {
    display: grid;
    font-weight: 100;
    grid-auto-columns: 25%;
    grid-auto-flow: column;
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-rows: repeat(12, 1fr);
    list-style: none;
    margin-top: 4rem;
    margin: 0;
    padding: 0;

    @media (max-width: 1300px) {
      grid-auto-columns: 33%;
      grid-template-rows: repeat(15, 1fr);
    }

    @media (max-width: 900px) {
      grid-auto-columns: 50%;
      grid-template-rows: repeat(23, 1fr);
    }

    @media (max-width: 600px) {
      grid-auto-columns: 100%;
      grid-template-rows: repeat(45, 1fr);
    }
  }

  .public-collections__list-item {
    /* border: 1px solid green; */
    padding: 0.9rem 0;
  }
`
