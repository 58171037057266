export const MIXED_EXHIBITIONS_DATA = [
  {
    year: '',
    gallery: 'Adam Gallery',
    location: 'London & Bath',
  },
  {
    year: '',
    gallery: 'Agnews',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Alresford Gallery',
    location: 'Hampshire',
  },
  {
    year: '',
    gallery: 'Arts Festival of Hong Kong',
    location: 'Hong Kong',
  },
  {
    year: '',
    gallery: 'Brandler Galleries',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Brian Sinfield Gallery',
    location: 'Oxfordshire',
  },
  {
    year: '',
    gallery: 'Bridgepoint Rye',
    location: 'East Sussex',
  },
  {
    year: '',
    gallery: 'Century Gallery',
    location: 'Windsor',
  },
  {
    year: '',
    gallery: 'Courcoux & Courcoux',
    location: 'Hampshire',
  },
  {
    year: '',
    gallery: 'City Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'David Messum Fine Art',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Duncan Campbell Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Duncan R. Miller Fine Arts',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Edinburgh Gallery',
    location: 'Edinburgh',
  },
  {
    year: '',
    gallery: 'Fairfax Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Folkestone Museum',
    location: 'Kent',
  },
  {
    year: '',
    gallery: 'Gallery 10',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Gallery 41',
    location: 'Somerset',
  },
  {
    year: '',
    gallery: 'Geedon Gallery',
    location: 'Essex',
  },
  {
    year: '',
    gallery: 'Goldmark',
    location: 'Rutland',
  },
  {
    year: '',
    gallery: 'Grafton House Gallery',
    location: 'Oxfordshire',
  },
  {
    year: '',
    gallery: 'Island Fine Arts',
    location: 'Isle Of Wight',
  },
  {
    year: '',
    gallery: 'John Moores',
    location: 'Liverpool',
  },
  {
    year: '',
    gallery: 'Jonleigh Gallery',
    location: 'Surrey',
  },
  {
    year: '',
    gallery: 'Langham Fine Art',
    location: 'Suffolk',
  },
  {
    year: '',
    gallery: 'Leicester Gallery',
    location: 'Leicestershire',
  },
  {
    year: '',
    gallery: 'Linden Hall Studios',
    location: 'Kent',
  },
  {
    year: '',
    gallery: 'Little Studio',
    location: 'New York',
  },
  {
    year: '',
    gallery: 'Lloyd’s of London',
    location: 'Lloyd’s of London',
  },
  {
    year: '',
    gallery: 'Manya Igel Fine Arts',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Mason Watts Fine Art',
    location: 'Warwick',
  },
  {
    year: '',
    gallery: 'Minster Gallery',
    location: 'Winchester',
  },
  {
    year: '',
    gallery: 'New English Art Club',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Newman & Saunders Galleries',
    location: 'USA',
  },
  {
    year: '',
    gallery: 'New Grafton Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'New Metropole',
    location: 'Kent',
  },
  {
    year: '',
    gallery: 'Odette Gilbert Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Pattersons Fine Art',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Piccadilly Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Royal Academy of Arts Summer Exhibition',
    location: 'London (Since 1953)',
  },
  {
    year: '',
    gallery: 'Royal Hibernian Academy',
    location: 'Dublin',
  },
  {
    year: '',
    gallery: 'Royal Scottish Academy',
    location: 'Edinburgh',
  },
  {
    year: '',
    gallery: 'Royal Society of Portrait Painters',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Royal West of England Academy',
    location: 'Bristol',
  },
  {
    year: '',
    gallery: 'Rye Art Gallery',
    location: 'East Sussex',
  },
  {
    year: '',
    gallery: 'Sandersons of Berners Street',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Southgate Gallery',
    location: 'Gloucestershire',
  },
  {
    year: '',
    gallery: 'Sladers Yard',
    location: 'Dorset',
  },
  {
    year: '',
    gallery: 'Stafford Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'St Hellier Galleries',
    location: 'Jersey',
  },
  {
    year: '',
    gallery: 'The Alton Gallery',
    location: 'Suffolk',
  },
  {
    year: '',
    gallery: 'The Royal Museum',
    location: 'Canterbury',
  },
  {
    year: '',
    gallery: 'Thompsons Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Walker Galleries',
    location: 'Harrogate',
  },
  {
    year: '',
    gallery: 'William Morris Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Wren Gallery',
    location: 'Oxfordshire',
  },
  {
    year: '',
    gallery: 'W H Patterson',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Zillah Bell Gallery',
    location: 'Yorkshire',
  },
]
