import React from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'

export default function BodyCopy({bodyHtml, classModifier = '', setMaxWidth = true, setMarginBottom = true}) {
  return (
    <StyledComponent
      className={`body-copy ${classModifier ? 'body-copy--' + classModifier : ''}`}
      setMaxWidth={setMaxWidth}
      setMarginBottom={setMarginBottom}
    >
      <Fade>
        <div dangerouslySetInnerHTML={{__html: bodyHtml}} className="markup-wrapper" />
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  font-weight: 100;
  margin-bottom: ${props => (props.setMarginBottom ? '6vw' : '0')};
  max-width: ${props => (props.setMaxWidth ? '72.8rem' : 'none')};

  @media (min-width: 700px) {
    margin-bottom: ${props => (props.setMarginBottom ? '5.5rem' : '0')};
  }

  p {
    line-height: 1.5;
  }

  a {
    font-weight: 400;
  }

  &--biography {
    max-width: 77rem;
  }

  .markup-wrapper {
    position: relative;
    padding-bottom: 2rem;

    /* &::after {
      content: '';
      display: block;
      width: 6rem;
      height: 2px;
      background-color: #666;
      position: absolute;
      bottom: 0;
      left: 0;
    } */
  }
`
