export const AWARDS_DATA = [
  {
    year: '1988',
    award: 'Grand Prix de l’Art Contemporaries',
  },
  {
    year: '1986',
    award: 'New English Club',
  },
  {
    year: '1977',
    award: 'Winner of Grand Prix Fine Art, Monaco',
  },
  {
    year: '',
    award: 'Sir Brinsley-Ford Award',
  },
]
