export const SOLO_EXHIBITIONS_DATA = [
  {
    year: '2021',
    gallery: 'Stafford Gallery',
    location: 'London',
  },
  {
    year: '2020',
    gallery: 'Adam Gallery',
    location: 'London & Bath',
  },
  {
    year: '',
    gallery: 'Stafford Gallery',
    location: 'London',
  },
  {
    year: '2019',
    gallery: 'Bridgepoint Rye',
    location: 'East Sussex',
  },
  {
    year: '',
    gallery: 'Adam Gallery',
    location: 'London & Bath',
  },
  {
    year: '2018',
    gallery: 'Adam Gallery',
    location: 'London & Bath',
  },
  {
    year: '2017',
    gallery: 'Royal Academy of Arts, Academicians in Focus',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Folkestone Museum',
    location: 'Kent',
  },
  {
    year: '2016',
    gallery: 'Adam Gallery',
    location: 'London & Bath',
  },
  {
    year: '2015',
    gallery: 'Stafford Gallery',
    location: 'London',
  },
  {
    year: '2014',
    gallery: 'Adam Gallery',
    location: 'London & Bath',
  },
  {
    year: '2013',
    gallery: 'Brian Sinfield Gallery',
    location: 'Oxfordshire',
  },
  {
    year: '',
    gallery: 'Fairfax Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Stafford Gallery',
    location: 'London',
  },
  {
    year: '2012',
    gallery: 'Brian Sinfield Gallery',
    location: 'Oxfordshire',
  },
  {
    year: '2011',
    gallery: 'Thompson’s Gallery',
    location: 'London',
  },
  {
    year: '2010',
    gallery: 'Adam Gallery',
    location: 'London',
  },
  {
    year: '2009',
    gallery: 'Brian Sinfield Gallery',
    location: 'Oxfordshire',
  },
  {
    year: '',
    gallery: 'Thompson’s Gallery',
    location: 'London',
  },
  {
    year: '2008',
    gallery: 'Adam Gallery',
    location: 'London & Bath',
  },
  {
    year: '2006',
    gallery: 'Adam Gallery',
    location: 'London & Bath',
  },
  {
    year: '',
    gallery: 'Thompson’s Gallery',
    location: 'London',
  },
  {
    year: '2005',
    gallery: 'Nevill Gallery',
    location: 'Canterbury',
  },
  {
    year: '2004',
    gallery: 'Thompson’s Gallery',
    location: 'London',
  },
  {
    year: '2002',
    gallery: 'Alresford Gallery',
    location: 'Hampshire',
  },
  {
    year: '',
    gallery: 'New Grafton Gallery',
    location: 'London',
  },
  {
    year: '2001',
    gallery: 'Royal Academy of Arts, Summer Exhibition Featured Artist',
    location: 'London',
  },
  {
    year: '',
    gallery: 'City Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Adam Gallery',
    location: 'London & Bath',
  },
  {
    year: '',
    gallery: 'Neville Gallery',
    location: 'Canterbury',
  },
  {
    year: '',
    gallery: 'Alresford Gallery',
    location: 'Hampshire',
  },
  {
    year: '',
    gallery: 'Thompson’s Gallery',
    location: 'London',
  },
  {
    year: '2000',
    gallery: 'New Grafton Gallery',
    location: 'London',
  },
  {
    year: '1999',
    gallery: 'Barneys Gallery',
    location: 'Greenwich, Connecticut, USA',
  },
  {
    year: '',
    gallery: 'City Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Neville Gallery',
    location: 'Canterbury',
  },
  {
    year: '',
    gallery: 'Alresfold Gallery',
    location: 'Hampshire',
  },
  {
    year: '',
    gallery: 'Jorgensen Fine Art',
    location: 'Dublin',
  },
  {
    year: '',
    gallery: 'Adam Gallery',
    location: 'London & Bath',
  },
  {
    year: '',
    gallery: 'Valley House Gallery',
    location: 'Dallas, Texas, USA',
  },
  {
    year: '1998',
    gallery: 'The Vogel Gallery',
    location: 'Dallas, Texas, USA',
  },
  {
    year: '',
    gallery: 'New Grafton Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Jonleigh Gallery',
    location: 'Guildford',
  },
  {
    year: '',
    gallery: 'Jorgenson Gallery',
    location: 'Dublin',
  },
  {
    year: '',
    gallery: 'Heyman, Barwell Jones',
    location: 'Norwich',
  },
  {
    year: '1997',
    gallery: 'City Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Alresfold Gallery',
    location: 'Hampshire',
  },
  {
    year: '',
    gallery: 'New Grafton Gallery',
    location: 'London',
  },
  {
    year: '1996',
    gallery: 'New Grafton Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Mason Watts Fine Art',
    location: 'Warwick',
  },
  {
    year: '',
    gallery: 'City Gallery',
    location: 'London',
  },
  {
    year: '1995',
    gallery: 'City Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Jonleigh Gallery',
    location: 'Guildford',
  },
  {
    year: '',
    gallery: 'New Grafton Gallery',
    location: 'London',
  },
  {
    year: '1994',
    gallery: 'New Grafton Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Sinfield Gallery',
    location: 'Oxfordshire',
  },
  {
    year: '',
    gallery: 'Mason Watts',
    location: 'Warwick',
  },
  {
    year: '',
    gallery: 'Jonleigh Gallery',
    location: 'Guildford',
  },
  {
    year: '1993',
    gallery: 'New Grafton Gallery',
    location: 'London',
  },
  {
    year: '1992',
    gallery: 'New Grafton Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Sinfield Gallery',
    location: 'Oxfordshire',
  },
  {
    year: '',
    gallery: 'Mason Watts Fine Art',
    location: 'Warwick',
  },
  {
    year: '1991',
    gallery: 'Drew Gallery',
    location: 'Canterbury',
  },
  {
    year: '1991',
    gallery: 'New Grafton Gallery',
    location: 'London',
  },
  {
    year: '1990',
    gallery: 'New Grafton Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Easton Rooms',
    location: 'East Sussex',
  },
  {
    year: '',
    gallery: 'Sinfield Gallery',
    location: 'Oxfordshire',
  },
  {
    year: '1988',
    gallery: 'New Grafton Gallery',
    location: 'London',
  },
  {
    year: '1987',
    gallery: 'Nevill Gallery',
    location: 'Canterbury',
  },
  {
    year: '',
    gallery: 'New Grafton Gallery',
    location: 'London',
  },
  {
    year: '1986',
    gallery: 'Linton Court Gallery',
    location: 'Yorkshire',
  },
  {
    year: '1985',
    gallery: 'New Grafton Gallery',
    location: 'London',
  },
  {
    year: '1984',
    gallery: 'Nevill Gallery',
    location: 'Canterbury',
  },
  {
    year: '1983',
    gallery: 'New Grafton Gallery',
    location: 'London',
  },
  {
    year: '1971',
    gallery: 'The New Metropole',
    location: 'Kent',
  },
  {
    year: 'c2005  ',
    gallery: 'Josef Middlekamp',
    location: 'Germany',
  },
  {
    year: 'Unknown ',
    gallery: 'Brandler Galleries',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Elgin Fine Art',
    location: 'Bath',
  },
  {
    year: '',
    gallery: 'Mason Watts',
    location: 'Warwick',
  },
  {
    year: '',
    gallery: 'Cootes Gallery',
    location: 'Lewes',
  },
  {
    year: '',
    gallery: 'David Paul Gallery',
    location: 'Chichester',
  },
  {
    year: '',
    gallery: 'Ogle Gallery',
    location: 'Eastbourne',
  },
  {
    year: '',
    gallery: 'Chilham Gallery',
    location: 'Kent',
  },
  {
    year: '',
    gallery: 'Gallery Corbett',
    location: 'Durham',
  },
  {
    year: '',
    gallery: 'Queens House Gallery',
    location: 'Kent',
  },
  {
    year: '',
    gallery: 'Thackeray Gallery',
    location: 'London',
  },
  {
    year: '',
    gallery: 'Morley Gallery',
    location: 'London',
  },
]
